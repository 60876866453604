export const breakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1440px',
  xxxl: '1600px',
  xxll: '1920px',
}

export const device = {
  mobile: `(min-width: ${breakpoints.sm})`,
  tablet: `(min-width: ${breakpoints.md})`,
  desktopLG: `(min-width: ${breakpoints.lg})`,
  desktopXL: `(min-width: ${breakpoints.xl})`,
  desktopXXL: `(min-width: ${breakpoints.xxl})`,
  desktopXXXL: `(min-width: ${breakpoints.xxxl})`,
  desktopXXLL: `(min-width: ${breakpoints.xxll})`,
}

export const sourceMedia = {
  sm: `(max-width: 575px)`,
  md: `(max-width: 767px)`,
  lg: `(max-width: 991px)`,
  xl: `(max-width: 1440px)`,
  xxl: `(max-width: 1920px)`,
}

export const widths = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
  xxll: 1920,
}

export const WIDTH_XS = 320
export const WIDTH_SM = 576
export const WIDTH_MD = 768
export const WIDTH_LG = 992
export const WIDTH_XL = 1200
export const WIDTH_XXL = 1440
export const WIDTH_XXXL = 1600
export const WIDTH_XXLL = 1920
